import t from '@jetshop/intl';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import ListFilterFlyouts from '@jetshop/ui/Filter/FilterTypes/List/ListFilterFlyouts';
import MultiListFlyouts from '@jetshop/ui/Filter/FilterTypes/Multi/MultiListFlyouts';
import RangeFilterFlyouts from '@jetshop/ui/Filter/FilterTypes/Range/RangeFilterFlyouts';
import Drawer, { DrawerTarget, DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import SortOrderDropdown from '@jetshop/ui/SortOrder/SortOrderDropdown';
import { ReactComponent as Carrot } from '@jetshop/ui/svg/Carrot.svg';
import theme from '@jetshop/ui/utils/theme';
import get from 'lodash.get';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'react-emotion';

import MaxWidth from '../../Layout/MaxWidth';
import FilterDrawer from './FilterDrawer';

import AtvBg from '../../../img/atv-kat.jpg';
import CrossBg from '../../../img/cross-kat.jpg';
import HeaderBg from '../../../img/header-background.jpg';
import MotorcycleBg from '../../../img/motorcycle-background.jpg';

import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';

const StyledMultiList = styled('section')`
  width: 100%;
  padding: 1.5rem 1rem 0.9rem 1rem;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-color: ${(props) => props.backgroundColor};
  background-position: ${(props) => props.backgroundPosition};
  background-repeat: no-repeat;
  background-size: cover;
  .scrolled & {
    padding: 0.5rem;
  }
  .grid {
    display: grid;
    grid-auto-flow: column;
    margin-bottom: 0.5rem;
    gap: 8px 8px;
    align-items: center;
    grid-template-columns: auto auto auto auto 0;
    padding-right: 29px;
    > div {
      margin-bottom: 0;
      button {
        text-align: left;
      }
    }
    button:not(.clear-filters) {
      height: 40px;
    }
  }
`;

const NarrowMaxWidth = styled(MaxWidth)`
  max-width: 70em;
`;

const FilterToggleButton = styled('button')`
  background: #d0302c;
  color: white;
  margin-bottom: 0;
  ${theme('below.md')} {
    width: 100%;
    padding: 4px;
  }
  &.active {
    background: #00b55b;
  }
  &:focus {
    outline: none;
  }
  span {
    display: block;

    &:first-child {
      text-transform: uppercase;
      font-size: 24px;
    }
    &:last-child {
      font-size: 11px;
    }
  }
`;

const FilterTitle = styled('div')`
  text-align: center;
  display: block;
  h1 {
    text-transform: uppercase;
    font-size: 2.5rem;
    color: white;
    margin-bottom: 5px;
    margin-top: 0;
  }
  h2 {
    text-transform: uppercase;
    font-size: 1.2rem;
    color: white;
    margin-bottom: 2rem;
  }
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  margin-bottom: ${theme('space.3')};
  button:first-of-type {
    margin-right: ${theme('space.1')};
  }
  ${theme('above.md')} {
    padding: ${theme('space.1')};
  }
  svg {
    transform: rotate(-90deg);
  }
`;

const MobileButton = styled('button')`
  height: 40px;
  background: #ffffff;
  border: 1px solid #cccccc;
  width: 50%;
  cursor: pointer;
  text-align: left;
  padding: ${theme('space.1')};
  font-weight: ${theme('fontWeights.semibold')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Filters = styled('div')`
  width: 100%;
  display: grid;
  grid-auto-columns: repeat(4, 1fr);
  grid-auto-flow: column;
  grid-gap: 8px;
  margin-bottom: 0.5rem;

  button {
    text-transform: uppercase;
  }

  &.hide {
    display: none;
  }
`;

const FilterHolder = styled('div')`
  &.scrolled {
    position: fixed;
    z-index: 998;
    width: 100%;
    top: 115px;
    > div {
      padding: 5px 0 0;
    }
  }
`;

export const ClearButton = styled('button')`
  border-radius: 100%;
  background: #fff;
  width: 30px;
  height: 30px;
  font-size: 1rem;
  line-height: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  ${theme('below.md')} {
    margin-top: 10px;
  }
`;

const StyledPlaceholder = styled('span')`
  width: 100%;
  position: relative;
  background-color: #ffffff;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 700;
  padding-left: 16px;
  padding-right: 16px;
  opacity: 0.8;
`;

const FilterPlaceHolder = ({ values }) => (
  <Fragment>
    {values.map((value, index) => (
      <StyledPlaceholder key={`ph-${index}`}>{value}</StyledPlaceholder>
    ))}
  </Fragment>
);

const URL_PARAMS = {
  BRAND: 'brand',
  YEAR: 'year',
  MODEL: 'model',
};

const STORAGE_KEYS = {
  BRAND: 'savedBrand',
  YEAR: 'savedYear',
  MODEL: 'savedModel',
};

export const FilterToggler = ({
  filters,
  activeFilters,
  toggleFilter,
  applyFilters,
  clearFilters,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [filterState, setFilterState] = useState({
    brand: null,
    year: null,
    model: null,
    isActive: false,
  });

  const multiListFilter = useMemo(() => {
    const filter = filters?.find((f) => f.__typename === 'MultiListFilter');
    if (!filter) return null;

    const baseId = filter.id.split(':').pop();
    return { ...filter, id: baseId };
  }, [filters]);

  const setFilterValues = useCallback((brand, year, model) => {
    setFilterState({
      brand,
      year,
      model,
    });
  }, []);

  const updateUrlParams = useCallback(
    (brand, year, model) => {
      const searchParams = new URLSearchParams(location.search);

      if (brand && year && model) {
        searchParams.set(URL_PARAMS.BRAND, brand);
        searchParams.set(URL_PARAMS.YEAR, year);
        searchParams.set(URL_PARAMS.MODEL, model);
      } else {
        searchParams.delete(URL_PARAMS.BRAND);
        searchParams.delete(URL_PARAMS.YEAR);
        searchParams.delete(URL_PARAMS.MODEL);
      }

      history.replace({ search: searchParams.toString() });
    },
    [history, location.search]
  );

  const createFilterObject = useCallback(
    (brand, year, model) => {
      if (!brand || !year || !model || !multiListFilter?.id) return null;
      return [
        {
          id: multiListFilter.id,
          values: [brand, year, model],
        },
      ];
    },
    [multiListFilter]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlBrand = searchParams.get(URL_PARAMS.BRAND);
    const urlYear = searchParams.get(URL_PARAMS.YEAR);
    const urlModel = searchParams.get(URL_PARAMS.MODEL);

    if (urlBrand && urlYear && urlModel) {
      setFilterValues(urlBrand, urlYear, urlModel);
      const filterObject = createFilterObject(urlBrand, urlYear, urlModel);
      if (filterObject) {
        localStorage.setItem(STORAGE_KEYS.BRAND, urlBrand);
        localStorage.setItem(STORAGE_KEYS.YEAR, urlYear);
        localStorage.setItem(STORAGE_KEYS.MODEL, urlModel);
        applyFilters({ multiListFilters: filterObject });
      }
    } else {
      const storedBrand = localStorage.getItem(STORAGE_KEYS.BRAND);
      const storedYear = localStorage.getItem(STORAGE_KEYS.YEAR);
      const storedModel = localStorage.getItem(STORAGE_KEYS.MODEL);

      if (storedBrand && storedYear && storedModel) {
        setFilterValues(storedBrand, storedYear, storedModel);
      }
    }
  }, []);

  const handleToggle = useCallback(() => {
    const { brand, year, model } = filterState;
    const isCurrentlyActive = brand && year && model && filterState.isActive;

    if (isCurrentlyActive) {
      clearFilters();
      updateUrlParams(null, null, null);
      setFilterState((prev) => ({ ...prev, isActive: false }));
    } else {
      if (brand && year && model) {
        const filterObject = createFilterObject(brand, year, model);
        if (filterObject) {
          applyFilters({ multiListFilters: filterObject });
          updateUrlParams(brand, year, model);
          setFilterState((prev) => ({ ...prev, isActive: true }));
        }
      }
    }

    toggleFilter();
  }, [
    filterState,
    toggleFilter,
    clearFilters,
    createFilterObject,
    applyFilters,
    updateUrlParams,
  ]);

  useEffect(() => {
    const { brand, year, model, isActive } = filterState;
    if (isActive) {
      updateUrlParams(brand, year, model);
    } else {
      updateUrlParams(null, null, null);
    }
  }, [filterState, updateUrlParams]);

  useEffect(() => {
    const activeValues = activeFilters?.multiListFilters?.[0]?.values;
    if (activeValues?.length === 3) {
      const [activeBrand, activeYear, activeModel] = activeValues;
      setFilterState((prev) => ({
        brand: activeBrand,
        year: activeYear,
        model: activeModel,
        isActive: true,
      }));
    } else if (!activeValues) {
      setFilterState((prev) => ({ ...prev, isActive: false }));
    }
  }, [activeFilters]);

  const isActive = useMemo(
    () =>
      filterState.brand &&
      filterState.year &&
      filterState.model &&
      filterState.isActive,
    [filterState]
  );

  return (
    <FilterToggleButton
      onClick={handleToggle}
      className={isActive ? 'active' : ''}
    >
      {isActive ? (
        <Fragment>
          <span>Filter på</span>
          <span>(endast produkter till din modell visas)</span>
        </Fragment>
      ) : (
        <Fragment>
          <span>Filter av</span>
          <span>(alla produkter i kategorin visas)</span>
        </Fragment>
      )}
    </FilterToggleButton>
  );
};

const Filter = ({
  backgroundImageUrl,
  backgroundColor,
  backgroundPosition,
  filters,
  applyFilters,
  clearFilters,
  activeFilters,
  toggleFilter,
  result,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [ssrDone, setSsrDone] = useState(false);

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 105);
    window.addEventListener('scroll', handleScroll);
    setSsrDone(true);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const sortOrders = get(this, 'props.sortOrders', []);
  const multiListFilter = filters.find(
    (f) => f.__typename === 'MultiListFilter'
  );

  const getSavedValues = useCallback(() => {
    if (!ssrDone || typeof window === 'undefined') return null;

    const brand = localStorage.getItem(STORAGE_KEYS.BRAND);
    const year = localStorage.getItem(STORAGE_KEYS.YEAR);
    const model = localStorage.getItem(STORAGE_KEYS.MODEL);

    if (brand && year && model) {
      return [brand, year, model];
    }
    return null;
  }, [ssrDone]);

  const savedValues = getSavedValues();

  const placeHolder =
    savedValues && multiListFilter?.id && !activeFilters.multiListFilters.length
      ? savedValues
      : null;

  const getBackgroundImage = useCallback(() => {
    if (!ssrDone) return '';

    const path = window.location.href;
    if (path.includes('motorcykel')) return MotorcycleBg;
    if (path.includes('snoskoter')) return HeaderBg;
    if (path.includes('atv-1')) return AtvBg;
    if (path.includes('cross')) return CrossBg;
    return '';
  }, [ssrDone]);

  const backgroundImage = backgroundImageUrl || getBackgroundImage();

  const handleClearFilters = useCallback(() => {
    clearFilters();
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.removeItem(STORAGE_KEYS.BRAND);
      localStorage.removeItem(STORAGE_KEYS.YEAR);
      localStorage.removeItem(STORAGE_KEYS.MODEL);
    }
  }, [clearFilters]);

  return (
    <Fragment>
      <Above
        breakpoint="md"
        render={() => (
          <Fragment>
            <Filters className="hide">
              <ListFilterFlyouts filters={filters} />
              <RangeFilterFlyouts filters={filters} />
              {sortOrders.length > 0 && (
                <SortOrderDropdown sortOrders={sortOrders} />
              )}
            </Filters>

            {multiListFilter && (
              <Filters>
                <FilterHolder className={isScrolled ? 'scrolled' : ''}>
                  <StyledMultiList
                    backgroundImage={backgroundImage}
                    backgroundColor={backgroundColor || ''}
                    backgroundPosition={backgroundPosition || 'center'}
                  >
                    {!isScrolled && (
                      <FilterTitle>
                        <h1>Välj din Maskin</h1>
                        <h2>Hitta rätt delar till din Maskin</h2>
                      </FilterTitle>
                    )}
                    <NarrowMaxWidth>
                      <div className="grid">
                        {placeHolder ? (
                          <FilterPlaceHolder values={placeHolder} />
                        ) : (
                          <MultiListFlyouts filters={filters} result={result} />
                        )}
                        <FilterToggler
                          clearFilters={clearFilters}
                          activeFilters={activeFilters}
                          applyFilters={applyFilters}
                          toggleFilter={toggleFilter}
                          filters={filters}
                        />
                        <ClearButton
                          onClick={handleClearFilters}
                          className="clear-filters"
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </ClearButton>
                      </div>
                    </NarrowMaxWidth>
                  </StyledMultiList>
                </FilterHolder>
              </Filters>
            )}
          </Fragment>
        )}
      />
      <Below
        breakpoint="md"
        render={() => (
          <Fragment>
            <ButtonWrapper>
              {sortOrders.length > 0 && (
                <DrawerTrigger id="sortDrawer">
                  {({ showTarget }) => (
                    <MobileButton onClick={showTarget}>
                      <p>{t('Sort By')}</p>
                      <Carrot />
                    </MobileButton>
                  )}
                </DrawerTrigger>
              )}
            </ButtonWrapper>

            {sortOrders.length > 0 && (
              <DrawerTarget id="sortDrawer">
                {({ hideTarget, isOpen }) => (
                  <Drawer isOpen={isOpen} right>
                    <FilterDrawer close={hideTarget} sortOrders={sortOrders} />
                  </Drawer>
                )}
              </DrawerTarget>
            )}
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default Filter;
